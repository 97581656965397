import { MealCategory } from './UserSettings';

interface UserRole {
  id: number;
  type: string;
}

export interface AdminManagedGroup {
  id: number
  name: string
  school: {
    name: string
  }
}

export interface User {
  id: number;
  email: string;
  name: string;
  surname: string;
  username: string;
  credits: number;
  role: UserRole;
  group: {
    name: string;
    gov_discount_applies: boolean;
    school: {
      id?: string;
      name: string;
      isSnackEnabled: boolean;
      isPaymentEnabled: boolean;
      prices: {
        breakfast: {
          default: string;
          allergy: string;
        };
        snack: {
          default: string;
          allergy: string;
        };
        lunch: {
          default: string;
          allergy: string;
          vegetarian: string;
        };
        afternoon_meal: {
          default: string;
          allergy: string;
          vegetarian: string;
        };
      };
    };
  };
  groups: AdminManagedGroup[]
  user_settings: {
    breakfast: MealCategory;
    lunch: MealCategory;
    afternoon_meal: MealCategory;
    snack: MealCategory;
    language: Language;
  };
  exp?: number; // Expiration of user's token, present only on self
}

export enum Language {
  EN = 'en',
  LT = 'lt',
  RU = 'ru',
}
