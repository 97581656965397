import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

import * as constants from '../constants/auth.constants';
import { User } from '../../abstractions/User';
// import { Error } from '../../abstractions/Error';
import Config from '../../Config';

const baseUrl = Config;

export interface Authenticate {
  type: constants.Authenticate;
  user: User;
}

function authenticate(user: User): Authenticate {
  const token = `Bearer ${window.localStorage.getItem('token')}`;
  axios.defaults.headers.common.Authorization = token;
  return {
    type: constants.AUTHENTICATE,
    user,
  };
}

export interface Unauthenticate {
  type: constants.Unauthenticate;
  savePathname: boolean;
}

function unauthenticate(savePathname: boolean): Unauthenticate {
  window.localStorage.removeItem('token');
  delete axios.defaults.headers.common['Authorization'];
  return {
    type: constants.UNAUTHENTICATE,
    savePathname,
  };
}

export interface AuthFailure {
  type: constants.AuthFailure;
  error: Error;
}

function authError(error: Error): AuthFailure {
  return {
    type: constants.AUTH_FAILURE,
    error,
  };
}

export interface ClearAuth {
  type: constants.ClearErrors;
}

function clearAuth(): ClearAuth {
  return {
    type: constants.CLEAR_ERRORS,
  };
}

export interface GetUser {
  type: constants.Me;
  child: User;
}

function getChild(child: User): GetUser {
  return {
    type: constants.ME,
    child,
  };
}

function decodeUser(token: string): User | null {
  try {
    return jwt_decode(token);
  } catch (error) {
    return null;
  }
}

export type AuthenticationAction = Authenticate | Unauthenticate | AuthFailure;

export function logIn(username: string, password: string) {
  return async (dispatch: Dispatch<AuthenticationAction, unknown, any>) => {
    try {
      window.localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      const response = await axios.post(`${baseUrl}/auth/local`, {
        identifier: username,
        password,
      });

      const user: User | null = response.data.user;
      if (user) {
        await window.localStorage.setItem('token', response.data.jwt);
        dispatch(authenticate(user));
      } else {
        dispatch(unauthenticate(false));
      }
    } catch (error) {
      dispatch(authError(error?.response));
    }
  };
}

export function logOut() {
  return async (dispatch: Dispatch<AuthenticationAction, unknown, any>) => {
    try {
      // await axios.post(`${baseUrl}/logout`, {});

      dispatch(unauthenticate(false));
    } catch (error) {
      dispatch(authError(error.response));
    }
  };
}

export function clearErrors() {
  return async (dispatch: Dispatch<AuthenticationAction, unknown, any>) => {
    dispatch(clearAuth());
  };
}

export function getChildInfo() {
  return async (dispatch: Dispatch<AuthenticationAction, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}/users/me`);
      dispatch(getChild(response.data));
    } catch (error) {
      window.localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      dispatch(authError(error.response));
    }
  };
}

export function checkAuthentication() {
  return async (dispatch: Dispatch<AuthenticationAction, unknown, any>) => {
    const token: string | null = await window.localStorage.getItem('token');

    if (token) {
      const user: User | null = decodeUser(token);
      const tokenExpirationTimestamp = user?.exp ? user.exp * 1000 : 0;
      const tokenIsExpired = moment(tokenExpirationTimestamp).isBefore(moment());
      if (user && !tokenIsExpired) {
        dispatch(authenticate(user));
      } else {
        dispatch(unauthenticate(true));
      }
    } else dispatch(unauthenticate(true));
  };
}
