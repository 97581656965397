import React from 'react';
import { Box, Image } from 'rebass';
import { FormattedMessage } from 'react-intl';
import colors from '../../../../Theme/Colors';
import { Meal } from '../../../../abstractions/Meal';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/index.reducer';

interface Props {
  name: string;
  image: string;
  isSelected: boolean;
  isCompleted: boolean;
  isHoliday: boolean;
  onClick: (name: string) => void;
}

export enum MealSelectionType {
  Active = 'Active',
  Selected = 'Selected',
  Next = 'Next',
}

const MealSelection = (props: Props) => {
  const { name, image, isSelected, isCompleted, isHoliday, onClick } = props;
  const isSnackEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isSnackEnabled;

  let mealBoxStyle = {
    maxWidth: '160px',
    width: ['100px', '160px', '160px'],
    cursor: 'pointer',
    opacity: '1',
  };
  let mealTypeDividerWidth = '48px';

  if (isSnackEnabled) {
    mealBoxStyle = {
      ...mealBoxStyle,
      maxWidth: '115px',
      width: ['100px', '115px', '115px'],
    };
    mealTypeDividerWidth = '20px';
  }

  if (isHoliday) {
    mealBoxStyle = {
      ...mealBoxStyle,
      cursor: 'not-allowed',
      opacity: '0.6',
    };
  }

  let mealSelectionStyle;
  if (isCompleted) {
    mealSelectionStyle = {
      border: '1px solid',
      borderColor: colors.brightGreen,
      backgroundColor: colors.lightGreen,
      borderRadius: '16px',
      textAlign: 'center',
    };
  } else if (isSelected) {
    mealSelectionStyle = {
      border: '3px dashed',
      borderColor: colors.brightGreen,
      backgroundColor: colors.lightGreen,
      borderRadius: '16px',
      textAlign: 'center',
    };
  } else {
    mealSelectionStyle = {
      border: '1px dashed',
      borderColor: colors.brightGreen,
      backgroundColor: 'transparent',
      borderRadius: '16px',
      textAlign: 'center',
    };
  }

  return (
    <>
      <Box
        height={'80px'}
        sx={{
          ...mealSelectionStyle,
          ...mealBoxStyle,
        }}
        onClick={() => !isHoliday && onClick(name)}
      >
        <Image src={image} width={48} />
        <Box>
          <FormattedMessage id={`order.meal.${name}`} />
        </Box>
      </Box>
      {name !== Meal.Afternoon_meal && (
        <Box
          sx={{
            width: ['16px', mealTypeDividerWidth, mealTypeDividerWidth],
            height: '40px',
            borderBottom: '1px dashed',
            borderColor: colors.brightGreen,
          }}
        />
      )}
    </>
  );
};

export default MealSelection;
