import breakfastIcon from '../images/svg/breakfast.svg';
import snackIcon from '../images/svg/snack.svg';
import lunchIcon from '../images/svg/lunch.svg';
import dinnerIcon from '../images/svg/dinner.svg';
import { Meal } from '../abstractions/Meal';

interface nameTypePair {
  name: string;
  type: string;
}

export type mealStateType = {
  name: Meal;
  image: string;
  types: nameTypePair[];
};

const mealStateSnackEnabled: mealStateType[] = [
  {
    name: Meal.Breakfast,
    image: breakfastIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
  {
    name: Meal.Snack,
    image: snackIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
  {
    name: Meal.Lunch,
    image: lunchIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Vegetarian',
        type: 'vegetarian',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
  {
    name: Meal.Afternoon_meal,
    image: dinnerIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Vegetarian',
        type: 'vegetarian',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
];

const mealTypeSnackDisabled: mealStateType[] = [
  {
    name: Meal.Breakfast,
    image: breakfastIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
  {
    name: Meal.Lunch,
    image: lunchIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Vegetarian',
        type: 'vegetarian',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
  {
    name: Meal.Afternoon_meal,
    image: dinnerIcon,
    types: [
      {
        name: 'Default',
        type: 'default',
      },
      {
        name: 'Vegetarian',
        type: 'vegetarian',
      },
      {
        name: 'Allergies',
        type: 'allergy',
      },
      {
        name: 'No food',
        type: 'none',
      },
    ],
  },
];

export const getMealState = (snackEnabled: boolean | undefined): mealStateType[] => {
  return snackEnabled ? mealStateSnackEnabled : mealTypeSnackDisabled;
};
