import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Heading } from 'rebass';
import { getChildInfo } from '../../redux/actions/auth.actions';
import { AppState } from '../../redux/reducers/index.reducer';
import NavBar from '../Order/NavBar/NavBar';
import theme from '../../Theme/Theme';
import Button from '../../common/Button/Button';
import Text from '../../common/Text/Text';
import colors from '../../Theme/Colors';
import { getCart, postCartOutdated, postCheckout } from '../../redux/actions/cart.actions';
import PricesInformation from './PricesInformation/PricesInformation';
import DayOrderRows from './DayOrderRows/DayOrderRows';
import { getOrders } from '../../redux/actions/order.actions';
import InvoiceInfoModal from './InvoiceInfoModal/InvoiceInfoModal';
import { useHistory } from 'react-router-dom';
import { isCartItemEmptyOrChanged } from './helpers/isCartItemEmptyOrChanged';
import Cart from '../../abstractions/Cart';
import { getGrandTotal } from '../../helpers/cart/getGrandTotal';

const getConfimPayElement = (cart: Cart | null, credits?: number) => {
  const total = getGrandTotal(cart, credits);
  if (total < 0) {
    return <FormattedMessage id={`cart.confirm`} />;
  }
  return <FormattedMessage id={`cart.payNow`} />;
};

const Checkout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const child = useSelector((state: AppState) => state.authReducer.child);
  const orders = useSelector((state: AppState) => state.orderReducer.orders);
  const pricing = useSelector((state: AppState) => state.pricingReducer.pricing);
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const is_payment = useSelector((state: AppState) => state.cartReducer?.is_payment);
  const paymentUrl = useSelector((state: AppState) => state.cartReducer?.url);
  const coveredByCredits = useSelector((state: AppState) => state.cartReducer?.coveredByCredits);
  const isPaymentEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isPaymentEnabled;
  const settingsUpdated = useSelector((state: AppState) => state.settingsReducer.settingsUpdated);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const credits = useSelector((state: AppState) => state.authReducer?.child?.credits);
  const cartOutdated = useSelector((state: AppState) => state.cartReducer?.cartOutdated);
  const isLoading = useSelector((state: AppState) => state.cartReducer?.isLoading);

  console.log('isLoading', isLoading)

  useEffect(() => {
    if (cartOutdated) {
      dispatch(postCartOutdated());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartOutdated]);

  useEffect(() => {
    if (!child) {
      dispatch(getChildInfo());
    } else if (!orders) {
      dispatch(getOrders());
    } else if (!cart && isPaymentEnabled) {
      dispatch(getCart());
    }
  }, [child, dispatch, pricing, cart, orders, isPaymentEnabled]);

  useEffect(() => {
    if (settingsUpdated) {
      dispatch(getChildInfo());
    }
  }, [settingsUpdated, dispatch]);

  useEffect(() => {
    if (cart && is_payment && paymentUrl && !coveredByCredits) {
      window.location.href = paymentUrl;
    } else if (cart && !is_payment && coveredByCredits) {
      history.push('/');
    }
  }, [is_payment, paymentUrl, cart, coveredByCredits, history]);

  if (!child) {
    return (
      <Flex alignItems="center" justifyContent="center" width="100%" height="100vh">
        <Spin />
      </Flex>
    );
  }

  const isCartPayable = () => {
    const isPayable = cart?.items?.some((item) => {
      if (isCartItemEmptyOrChanged(item)) {
        return false;
      }
      return true;
    });

    return isPayable;
  };

  return (
    <>
      <NavBar child={child} />
      <Box width={['auto', '80%', '80%']} margin={'15px auto 0 auto'}>
        <Heading fontFamily={theme.fonts.headingBold} textAlign="center" marginBottom={20}>
          <FormattedMessage id="checkout.title" />
        </Heading>
        <DayOrderRows cart={cart} />
        <PricesInformation />
        <Flex style={{ marginTop: '20px', justifyContent: 'center' }}>
          {isCartPayable() ? (
            <Box>
              <Button
                width="100%"
                padding="10px 24px"
                margin="20px 10px"
                backgroundColor={colors.lightGreen}
                border={`1px solid ${colors.brightGreen}`}
                maxWidth="300px"
                loading={isLoading}
                onClick={() => {
                  dispatch(postCheckout());
                }}
              >
                <Text color={colors.darkGreen}>{getConfimPayElement(cart, credits)}</Text>
              </Button>
              {/* <PayLaterModal /> TODO: Add it back when clients are more acquaited with the system */}
            </Box>
          ) : (
            <Heading fontFamily={theme.fonts.headingBold} textAlign="center" marginBottom={20}>
              <FormattedMessage id="checkout.emptyCart" />
            </Heading>
          )}
        </Flex>
        <Flex style={{ marginTop: '20px', justifyContent: 'center' }}>
          <Text
            color={colors.darkGreen}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setIsInvoiceModalOpen(true);
            }}
          >
            <FormattedMessage id={`cart.getInvoice`} />
          </Text>
        </Flex>
      </Box>
      <InvoiceInfoModal
        isOpen={isInvoiceModalOpen}
        handleCloseModal={() => {
          setIsInvoiceModalOpen(false);
        }}
      />
    </>
  );
};

export default Checkout;
