import React, { useEffect, useState } from 'react';
import { Box, Flex, Image } from 'rebass';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import momentBusinessDay from 'moment-business-days';

import MealSelection from './MealSelection/MealSelection';
import treeBig from '../../../images/svg/treeBig.svg';
import leaveWithGrass from '../../../images/svg/leaveWithGrass.svg';
import Card from '../../../common/Card/Card';
import { Order } from '../../../abstractions/Order';
import colors from '../../../Theme/Colors';
import SelectedDate from '../SelectedDate/SelectedDate';
import { getMenuByDate, getOrders, postOrder } from '../../../redux/actions/order.actions';
import { getMealState, mealStateType } from '../../../util/mealState';
import { AppState } from '../../../redux/reducers/index.reducer';
import { MealCategory } from '../../../abstractions/UserSettings';
import { postCart } from '../../../redux/actions/cart.actions';
import HolidaySection from '../HlidaySection/HolidaySection';
import { getChildInfo } from '../../../redux/actions/auth.actions';

interface Props {
  order?: Order;
  selectedDay: Moment;
  selectedEndDay: Moment;
  weekDay: string;
  onSelectStartDate: (date: Moment | null) => void;
  onSelectEndDate: (date: Moment | null) => void;
}

const MealComponent = (props: Props) => {
  const { order, selectedDay, selectedEndDay, weekDay, onSelectStartDate, onSelectEndDate } = props;
  const dispatch = useDispatch();
  const isSnackEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isSnackEnabled;
  const isPaymentEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isPaymentEnabled;
  const user = useSelector((state: AppState) => state.authReducer.child);
  const mealState: mealStateType[] = getMealState(isSnackEnabled);
  const defaultMenu = useSelector((state: AppState) => state.orderReducer.orderDefaultMenu);
  const vegetarianMenu = useSelector((state: AppState) => state.orderReducer.orderVegetarianMenu);
  const allergyMenu = useSelector((state: AppState) => state.orderReducer.orderAllergyMenu);
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const settingsUpdated = useSelector((state: AppState) => state.settingsReducer.settingsUpdated);
  const orders = useSelector((state: AppState) => state.orderReducer.orders);
  const isHoliday = !order;

  const [selectedMeal, setSelectedMeal] = useState(mealState[0].name);

  useEffect(() => {
    if (settingsUpdated) {
      dispatch(getChildInfo());
    }
  }, [settingsUpdated, dispatch]);

  if (settingsUpdated) {
    dispatch(getOrders());
  }

  useEffect(() => {
    if (user && !defaultMenu) {
      dispatch(getMenuByDate(selectedDay, user, MealCategory.Default));
    }
  }, [dispatch, selectedDay, user, defaultMenu]);

  useEffect(() => {
    if (user && !vegetarianMenu) {
      dispatch(getMenuByDate(selectedDay, user, MealCategory.Vegetarian));
    }
  }, [dispatch, selectedDay, user, vegetarianMenu]);

  useEffect(() => {
    if (user && !allergyMenu) {
      dispatch(getMenuByDate(selectedDay, user, MealCategory.Allergy));
    }
  }, [dispatch, selectedDay, user, allergyMenu]);

  const onChangeDate = (date: Moment, changeDateFunc: () => void) => {
    if (user) {
      dispatch(getMenuByDate(date, user, MealCategory.Default));
      dispatch(getMenuByDate(date, user, MealCategory.Vegetarian));
    }
    changeDateFunc();
  };

  const createNewOrder = (selectedMealType: any, selectedMeal: any, isDoubleOrder: boolean) => {
    let newOrderAmount = 1;
    if (isDoubleOrder) {
      // @ts-ignore;
      const orderSelectedMealAmount = order?.[selectedMealType?.name + '_amount'];
      if (!orderSelectedMealAmount || orderSelectedMealAmount < 2) {
        newOrderAmount = 2;
      } else {
        newOrderAmount = 1;
      }
    }

    const doesOrderExistForDate = (date: Moment) =>
      orders?.find((order) => order?.date === date.format('YYYY-MM-DD'));

    if (selectedEndDay) {
      const currentMoment = momentBusinessDay(selectedDay);
      let newOrders = [] as any;
      while (currentMoment.isBefore(selectedEndDay, 'day')) {
        if (!isPaymentEnabled) delete order?.id;
        if (doesOrderExistForDate(currentMoment)) {
          newOrders.push({
            ...order,
            date: currentMoment.format('YYYY-MM-DD'),
            [selectedMealType?.name]: selectedMeal?.type,
            [selectedMealType?.name + '_amount']: newOrderAmount,
          });
        }
        currentMoment.nextBusinessDay();
      }
      if (doesOrderExistForDate(currentMoment)) {
        newOrders.push({
          ...order,
          date: currentMoment.format('YYYY-MM-DD'),
          [selectedMealType?.name]: selectedMeal?.type,
          [selectedMealType?.name + '_amount']: newOrderAmount,
        });
      }

      if (isPaymentEnabled) {
        return dispatch(postCart(newOrders, cart));
      }
      return dispatch(postOrder(newOrders));
    }
  };

  const selectedMealType = mealState.find((meal) => meal.name === selectedMeal);

  return (
    <>
      <Box width={['90%', '90%', '80%']} margin="0 auto" display={['block', 'block', 'flex']}>
        <Flex marginTop={10}>
          {mealState?.map((meal, index) => {
            const getSelectedMealIndex = mealState.findIndex((meal) => meal.name === selectedMeal);
            return (
              <MealSelection
                key={meal?.name}
                name={meal?.name}
                image={meal?.image}
                isSelected={meal?.name === selectedMeal}
                isCompleted={
                  mealState[index].name !== selectedMeal && index <= getSelectedMealIndex
                }
                isHoliday={isHoliday}
                onClick={(name) => {
                  // @ts-ignore;
                  setSelectedMeal(mealState?.find((meal) => meal?.name === name)?.name);
                }}
              />
            );
          })}
        </Flex>
        <SelectedDate
          startDate={selectedDay}
          endDate={selectedEndDay}
          weekDay={weekDay}
          onStartDateChange={(date) => onChangeDate(moment(date), () => onSelectStartDate(date))}
          onChangeEndDate={(date) => onChangeDate(moment(date), () => onSelectEndDate(date))}
        />
      </Box>
      <Box
        backgroundColor={colors.lightGrey}
        height={['100%', '100%', 238]}
        sx={{
          overflow: 'visible',
          position: 'relative',
          margin: [
            '67px 20px 0 20px',
            '10px 20px 0 20px',
            `${order ? '200px' : '100px'} 20px 0 20px`,
          ],
          borderRadius: '16px',
        }}
      >
        {!isHoliday && (
          <Image
            src={treeBig}
            height="240px"
            width="154px"
            sx={{
              position: ['absolute'],
              left: ['-30px', '-30px', 'auto'],
              bottom: ['auto', '20px', '80px'],
              userSelect: 'none',
            }}
          />
        )}
        <Image
          src={leaveWithGrass}
          height="195px"
          width="230px"
          sx={{ position: ['absolute'], bottom: '15px', right: '-60px', userSelect: 'none' }}
        />
        <Box
          height={['100%', '100%', 238]}
          width={'80%'}
          sx={{
            overflow: 'visible',
            position: 'relative',
            margin: '0 auto',
            left: ['0', '0', '-16px'],
            top: ['-43px', '-43px', '0'],
          }}
        >
          <Box
            display={['block', 'block', 'flex']}
            margin="0 auto"
            sx={{
              position: ['block', 'block', 'absolute'],
              bottom: '11px',
              '>div:not(:last-child)': {
                marginRight: ['0', '0', '30px'],
              },
            }}
          >
            {!isHoliday ? (
              selectedMealType?.types?.map((selectedMeal) => {
                //@ts-ignore
                const isChecked = order?.[selectedMealType?.name] === selectedMeal?.type;
                return (
                  <Card
                    key={selectedMeal?.name}
                    selectedMeal={selectedMeal}
                    selectedMealType={selectedMealType}
                    isChecked={isChecked}
                    order={order}
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                      createNewOrder(selectedMealType, selectedMeal, false);
                    }}
                    onDoubleClick={() => createNewOrder(selectedMealType, selectedMeal, true)}
                  />
                );
              })
            ) : (
              <HolidaySection selectedDate={selectedDay} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MealComponent;
