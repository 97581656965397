import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Image } from 'rebass';
import colors from '../../Theme/Colors';
import Text from '../../common/Text/Text';

import { MealCategory } from '../../abstractions/UserSettings';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/index.reducer';
import { Order } from '../../abstractions/Order';
import './Card.css';
import plus from '../../images/svg/plus.svg';
import remove from '../../images/svg/remove.svg';
import { FormattedMessage } from 'react-intl';
import { Spin, Badge } from 'antd';
import theme from '../../Theme/Theme';
import { DayOrderMenuItem } from '../../abstractions/Menu';
import { Meal } from '../../abstractions/Meal';
import PaymentStatusLabel from '../PaymentStatusLabel/PaymentStatusLabel';

interface Props {
  selectedMeal: any;
  isChecked?: boolean;
  color?: string;
  order?: Order;
  selectedMealType: any;
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
  onClick: () => void;
}

const Card = (props: Props) => {
  const {
    selectedMeal: { type },
    selectedMealType,
    isChecked,
    color,
    onDoubleClick,
    order,
    onClick,
  } = props;
  //@ts-ignore
  const selectedMealAmount = order?.[selectedMealType?.name + '_amount'] ?? 1;
  const isDoubleChecked = !!selectedMealAmount && selectedMealAmount > 1;
  const pricing = useSelector((state: AppState) => state.pricingReducer.pricing);
  const child = useSelector((state: AppState) => state.authReducer.child);
  const isPaymentEnabled = child?.group?.school?.isPaymentEnabled;
  const [isCardLoading, setIsCardLoading] = useState(false);
  const isOrdersLoading = useSelector((state: AppState) => state.orderReducer.isLoading);
  const isCartLoading = useSelector((state: AppState) => state.cartReducer.isLoading);
  const defaultMenu = useSelector((state: AppState) => state.orderReducer.orderDefaultMenu);
  const vegetarianMenu = useSelector((state: AppState) => state.orderReducer.orderVegetarianMenu);
  const allergyMenu = useSelector((state: AppState) => state.orderReducer.orderAllergyMenu);

  const shouldDisplayPaymentStatusTag = isChecked && isPaymentEnabled;

  const displayedCardPrice = () => {
    if (isPaymentEnabled && pricing) {
      if (
        selectedMealType.name === Meal.Lunch &&
        type !== MealCategory.None &&
        child?.group?.gov_discount_applies
      ) {
        return (
          <>
            <s style={{ color: isChecked ? 'white' : 'black' }}>
              <Text
                color={cardHeaderStyle.headerColor}
                margin="3px 0 0 5px"
                weight="bold"
                size="14px"
              >
                {type !== 'none' ? (
                  // @ts-ignore
                  ` €${pricing?.[selectedMealType?.name]?.[type]?.price / 100}`
                ) : (
                  <></>
                )}
              </Text>
            </s>
            <Box
              sx={{
                '.ant-badge-count': {
                  boxShadow: '0 0 0 0',
                },
              }}
            >
              <Badge
                style={{ margin: '3px 0 0 5px' }}
                // @ts-ignore
                count={`€${pricing?.[selectedMealType?.name]?.[type]?.gov_discounted_price / 100}`}
              />
            </Box>
          </>
        );
      }
      return (
        <Text color={cardHeaderStyle.headerColor} margin="0 0 0 5px" weight="bold" size="18px">
          {/* @ts-ignore */}
          {type !== 'none' ? ` €${pricing?.[selectedMealType?.name]?.[type]?.price / 100}` : <></>}
        </Text>
      );
    }
    return (
      <Box width={35}>
        <Spin />
      </Box>
    );
  };

  useEffect(() => {
    if (!isOrdersLoading) setIsCardLoading(false);
  }, [isOrdersLoading]);

  useEffect(() => {
    if (!isCartLoading && isPaymentEnabled) setIsCardLoading(false);
  }, [isCartLoading, isPaymentEnabled]);

  const getBulletList = (menu: DayOrderMenuItem[] | null) => {
    let bulletList: any = [];
    if (menu && menu?.find((item: any) => item?.type === selectedMealType?.name)) {
      menu?.forEach((item: any) => {
        if (selectedMealType?.name === item?.type) {
          bulletList = [...bulletList, item];
        }
      });
      return bulletList;
    }
  };

  const formatBulletList = (bulletList: any) => (
    <ul>
      {bulletList?.map((meal: any) => (
        <li key={meal?.name}>
          <Text color={colors.darkGreen} size="13px">
            {meal?.name?.richText?.[0]?.text || meal?.name}
          </Text>
        </li>
      ))}
    </ul>
  );

  const cardHeaderStyle = {
    backgroundColor: colors.grey,
    headerColor: colors.darkGreen,
  };

  if (type === MealCategory.None) {
    cardHeaderStyle.backgroundColor = colors.red;
    cardHeaderStyle.headerColor = colors.white;
  } else if (isChecked) {
    cardHeaderStyle.backgroundColor = colors.brightGreen;
    cardHeaderStyle.headerColor = colors.white;
  }

  const textOnCard = (type: MealCategory) => {
    switch (type) {
      case MealCategory.Default:
        return formatBulletList(getBulletList(defaultMenu));
      case MealCategory.Allergy: {
        if (allergyMenu) {
          return formatBulletList(getBulletList(allergyMenu));
        }
        return <FormattedMessage id="order.mealType.allergy.description" />;
      }
      case MealCategory.Vegetarian: {
        if (vegetarianMenu) {
          return formatBulletList(getBulletList(vegetarianMenu));
        }
        return <FormattedMessage id={`order.mealType.vegetarian.description`} />;
      }
      case MealCategory.None:
        return <FormattedMessage id={`order.mealType.none.description`} />;
    }
  };

  const doublePortionSection = (
    <Flex
      onClick={onDoubleClick}
      backgroundColor={colors.grey}
      margin="6px 9px"
      sx={{
        borderRadius: '8px',
        alignItems: 'center',
        position: 'absolute',
        bottom: '0',
        width: '93%',
        height: '46px',
        cursor: 'pointer',
      }}
    >
      <Box
        height={40}
        width={40}
        backgroundColor={isDoubleChecked ? colors.red : colors.white}
        margin="4px"
        sx={{ borderRadius: '8px', width: '18%', display: 'flex', justifyContent: 'center' }}
      >
        <Image src={isDoubleChecked ? remove : plus} width={17} />
      </Box>
      <Text margin={'0 0 0 24px'}>
        <FormattedMessage id={`order.mealType.doublePortion`} />
      </Text>
    </Flex>
  );

  return (
    <Box>
      <Box
        height={380}
        width={254}
        sx={{
          backgroundColor: colors.white,
          marginBottom: '23px',
          position: 'relative',
          borderRadius: '16px',
          '.card-checkbox': {
            height: '24px',
            width: '24px',
            backgroundColor: props.color ? props.color : colors.grey,
          },
          '.card-checkbox input:checked ~ .checkmark': {
            border: 'solid white',
          },
        }}
      >
        <Box
          onClick={() => {
            if (!isChecked) {
              setIsCardLoading(true);
              return onClick();
            }
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Flex
            height={71}
            backgroundColor={cardHeaderStyle.backgroundColor}
            sx={{
              alignItems: 'center',
              borderRadius: '16px 16px 0 0',
              paddingLeft: '19px',
              input: {
                marginRight: '19px',
              },
            }}
          >
            {(isOrdersLoading || isCartLoading) && isCardLoading ? (
              <Box width={35}>
                <Spin />
              </Box>
            ) : (
              <>
                <label className="container">
                  <input type="checkbox" onChange={() => {}} checked={isChecked} color={color} />
                  <span className="checkmark"></span>
                </label>
              </>
            )}
            <Heading
              color={cardHeaderStyle.headerColor}
              fontFamily={theme.fonts.headingBold}
              fontSize="20px"
            >
              <FormattedMessage id={`order.mealType.${type}.title`} />
            </Heading>
            {isPaymentEnabled && displayedCardPrice()}
          </Flex>
        </Box>
        {shouldDisplayPaymentStatusTag && (
          <PaymentStatusLabel
            status={order?.status}
            date={order?.date}
            meal={selectedMealType.name}
            order={order ?? ({} as Order)}
          />
        )}
        <Box
          padding={
            type === MealCategory.Default ||
            (type === MealCategory.Vegetarian && vegetarianMenu) ||
            (type === MealCategory.Allergy && allergyMenu)
              ? '10px 20px 20px 0'
              : '20px'
          }
        >
          {textOnCard(type)}
        </Box>
        {isChecked && type !== MealCategory.None && doublePortionSection}
      </Box>
    </Box>
  );
};

export default Card;
