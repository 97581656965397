// TODO: Remove this when figure out why cicd is not working
import React, { useState, useEffect } from 'react';
import { Dropdown, Menu, Modal, Button as AntdButton } from 'antd';
import Button from '../../../../common/Button/Button';
import Text from '../../../../common/Text/Text';
import colors from '../../../../Theme/Colors';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Flex, Image } from 'rebass';
import dropdownIcon from '../../../../images/svg/dropdown.svg';
import successIcon from '../../../../images/svg/SuccessIcon.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/index.reducer';
import { changeUserSettings } from '../../../../redux/actions/settings.actions';
import { getMealState, mealStateType } from '../../../../util/mealState';

interface Props {
  isVisible: boolean;
  onShowModal: (isVisible: boolean) => void;
}

const DefaultSelectionsModal = (props: Props) => {
  const { isVisible, onShowModal } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector((state: AppState) => state.authReducer.child)?.user_settings;
  const isSnackEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isSnackEnabled;
  const defaultSelectionsUpdated = useSelector((state: AppState) => {
    return state.settingsReducer.defaultSelectionsUpdated;
  });
  const [settingsState, setSettingsState] = useState(settings);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (defaultSelectionsUpdated) {
      setSuccessMessage(true);
    }
  }, [defaultSelectionsUpdated, dispatch]);

  const mealState: mealStateType[] = getMealState(isSnackEnabled);

  const handleCancel = () => {
    setSettingsState(settings);
    setSuccessMessage(false);
    onShowModal(false);
  };

  const desktopButton = (
    <Box marginLeft="auto" display={['none', 'block', 'block']}>
      <Button padding="10px 12px" margin="0 17px 0 0" onClick={() => onShowModal(true)}>
        <Text color={colors.darkGreen}>
          {intl.formatMessage({ id: 'order.setAsDefault.button' })}
        </Text>
      </Button>
    </Box>
  );

  const mobileButton = (
    <Box marginLeft="auto" display={['block', 'none', 'none']}>
      <Box
        backgroundColor={colors.lightGrey}
        width="100%"
        sx={{
          borderRadius: '10px',
          marginTop: '10px',
          div: {
            padding: '9px 0 9px 15px',
          },
        }}
        onClick={() => onShowModal(true)}
      >
        <Text>{intl.formatMessage({ id: 'order.setAsDefault.button' })}</Text>
      </Box>
    </Box>
  );

  const menu = (meal: mealStateType) => (
    <Menu
      onClick={({ key }) => {
        // @ts-ignore
        setSettingsState((prev) => ({ ...prev, [meal.name]: key }));
      }}
    >
      {meal.types.map((item: any) => (
        <Menu.Item key={item.type}>
          <h3>
            <FormattedMessage id={`order.mealType.${item.type}.title`} />
          </h3>
        </Menu.Item>
      ))}
    </Menu>
  );

  const dropdown = (meal: mealStateType) => (
    <Dropdown overlay={menu(meal)} trigger={['click']}>
      <Flex
        sx={{
          border: '2px solid',
          borderColor: colors.mintGreen,
          cursor: 'pointer',
          borderRadius: '5px',
          padding: '5px',
          width: '100%',
        }}
      >
        <Flex width="100%">
          {/* @ts-ignore */}
          <FormattedMessage id={`order.mealType.${settingsState[meal.name]}.title`} />
          <Image src={dropdownIcon} marginLeft="auto" width={9} />
        </Flex>
      </Flex>
    </Dropdown>
  );

  return (
    <>
      {desktopButton}
      {mobileButton}
      <Modal
        title={intl.formatMessage({ id: 'order.setAsDefault.button' })}
        open={isVisible}
        width="90%"
        onCancel={handleCancel}
        footer={[]}
      >
        {successMessage ? (
          <Box sx={{ textAlign: 'center' }}>
            <Image src={successIcon} width={60} margin="0 0 30px 0" />
            <h1 style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'order.setAsDefault.success.title' })}
            </h1>
            <h3 style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'order.setAsDefault.success.subtitle' })}
            </h3>
            <h3 style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'order.setAsDefault.success.ordersInCalendar' })}
            </h3>
            <AntdButton
              onClick={() => {
                handleCancel();
              }}
              type="primary"
              htmlType="submit"
              style={{
                borderColor: colors.secondaryRed,
                backgroundColor: colors.secondaryRed,
                borderRadius: '5px',
                color: colors.white,
              }}
            >
              {intl.formatMessage({ id: 'logout' })}
            </AntdButton>
            <h3 style={{ textAlign: 'center', marginTop: '30px' }}>
              {intl.formatMessage({ id: 'order.setAsDefault.success.goToOrderScreen.title' })}
            </h3>
            <AntdButton
              onClick={() => {
                handleCancel();
              }}
              type="primary"
              htmlType="submit"
              style={{
                borderColor: colors.mintGreen,
                backgroundColor: colors.mintGreen,
                borderRadius: '5px',
                color: colors.black14,
              }}
            >
              {intl.formatMessage({ id: 'order.setAsDefault.success.goToOrderScreen.button' })}
            </AntdButton>
          </Box>
        ) : (
          <>
            <h2 style={{ textAlign: 'center' }}>
              <FormattedMessage id="order.setAsDefault.description.start" />
              <FormattedMessage
                id={`order.setAsDefault.description.${isSnackEnabled ? 'snack' : 'breakfast'}`}
              />
              <FormattedMessage id="order.setAsDefault.description.end" />
            </h2>
            <Box
              marginTop={30}
              display={['block', 'flex', 'flex']}
              sx={{ justifyContent: 'center' }}
            >
              {mealState.map((meal) => (
                <Box marginRight={20} key={meal?.name}>
                  <Box
                    height={'80px'}
                    maxWidth={'160px'}
                    width={['100px', '160px', '160px']}
                    sx={{ textAlign: 'center', margin: '0 auto' }}
                  >
                    <Image src={meal?.image} width={48} />
                    <Box>
                      <h3>
                        <FormattedMessage id={`order.meal.${meal?.name}`} />
                      </h3>
                    </Box>
                  </Box>
                  {dropdown(meal)}
                </Box>
              ))}
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <h4 style={{ textAlign: 'center', color: colors.mintGreen, margin: '40px 0 20px 0' }}>
                <FormattedMessage id="order.setAsDefault.asterix" />
              </h4>
              <AntdButton
                onClick={() => {
                  settings && dispatch(changeUserSettings({ ...settings, ...settingsState }));
                }}
                type="primary"
                htmlType="submit"
                style={{
                  borderColor: colors.mintGreen,
                  backgroundColor: colors.mintGreen,
                  borderRadius: '5px',
                  color: colors.black14,
                }}
              >
                <FormattedMessage id="order.settings.save" />
              </AntdButton>
            </Box>
          </>
        )}
      </Modal>
    </>
  );
};

export default DefaultSelectionsModal;
